import type { Payload } from "@local/payload-client/src/types";
import { classNames } from "@local/utils/src/classNames";
interface Props {
  name: string;
  size: 8 | 10 | 12 | 16;
  image?: Payload.Media | string;
}
export const Avatar = ({
  image,
  name,
  size
}: Props) => {
  const initials = name.split(" ").map(n => n[0]).join("");
  return <div className={classNames("inline-flex items-center justify-center rounded-full bg-gray-300 aspect-square", size === 8 ? "h-8 w-8" : size === 10 ? "h-10 w-10" : size === 12 ? "h-12 w-12" : "h-16 w-16")} style={image ? {
    backgroundImage: `url(${(image as Payload.Media)?.url ? (image as Payload.Media).url : image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat"
  } : {}} data-sentry-component="Avatar" data-sentry-source-file="Avatar.tsx">
      {!image && <span className="font-medium leading-none text-gray-700">
          {initials}
        </span>}
    </div>;
};