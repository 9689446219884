"use client";

import type { Payload } from "@local/payload-client/src/types";
import { ContentBlockServer } from "../ContentBlock";
import { type RatingScores, Stars } from "./Stars";
import { Avatar } from "../ui/Avatar";
import { useMemo, useState } from "react";
import ButtonServer, { ButtonIntent } from "../ui/button";
import { MINISTORE } from "@/lib/const";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { classNames } from "@local/utils";
import { trackAction } from "@local/tracking/src/trackActions";
import Image from "@/components/Image";
interface Props {
  block: Payload.FullWidthReviewsBlock;
}
interface SingleReviewProps {
  review: Payload.SingleReview;
  className?: string;
}
export const SingleReview = ({
  review,
  className
}: SingleReviewProps) => {
  const [readMore, setReadMore] = useState(false);
  const openReview = () => {
    setReadMore(true);
    trackAction("review_clicked", {
      rating: review.starsRating,
      review_id: review.id
    });
  };
  return <div className={classNames("flex flex-col w-full gap-4 md:mb-8", className ? className : "")} data-sentry-component="SingleReview" data-sentry-source-file="FullWidthReviews.tsx">
      {review.image?.url && <Image src={review.image.url} width={256} height={256} alt={`review from ${review.name}`} className="aspect-square" />}
      <div>
        <Stars rating={parseFloat(review.starsRating) as RatingScores} overrideColor={MINISTORE === "baerskintactical" ? "#1BB57C" : "#fbbf24"} data-sentry-element="Stars" data-sentry-source-file="FullWidthReviews.tsx" />
      </div>
      <p>
        {readMore ? review.content : review.content.substring(0, 94) + "..."}
      </p>
      {!readMore && <button onClick={() => openReview()} className="text-left underline w-fit">
          Read More
        </button>}
      <div className="flex flex-row items-center gap-4">
        <Avatar name={review.name} size={12} image={review.avatar} data-sentry-element="Avatar" data-sentry-source-file="FullWidthReviews.tsx" />

        <div>
          <p className="mb-1 leading-tight">
            <b>{review.name}</b>

            <span className="block text-sm font-gray-700">
              {review.location}
            </span>
          </p>
        </div>
      </div>
    </div>;
};
export const FullWidthReviewsClient = ({
  block
}: Props) => {
  const [amountToDisplay, setAmount] = useState(4);
  const reviews = useMemo(() => block.reviews?.slice(0, amountToDisplay), [amountToDisplay, block]);

  // useEffect(() => {
  //   setReviews(block.reviews.slice(0, showMoreClicks ? 4 * showMoreClicks : 4))
  // }, [showMoreClicks, block.reviews])

  return block.reviews ? <ContentBlockServer className="py-10 mb-4 text-gray-900" data-sentry-element="ContentBlockServer" data-sentry-component="FullWidthReviewsClient" data-sentry-source-file="FullWidthReviews.tsx">
      <div className="relative" id="reviews">
        <div className="absolute top-0 right-0 z-20 w-2/12 h-full sm:invisible sm:hidden bg-gradient-to-r from-transparent to-white" />
        <h2 className="px-8 mb-6 text-3xl font-bold text-center lg:text-3xl">
          {block.headline}
        </h2>
        <ul className="invisible hidden grid-cols-1 gap-6 mx-10 sm:grid sm:visible sm:grid-cols-2 md:grid-cols-4">
          {reviews?.map(review => <li key={`single-review-${review.id}`} className="list-none">
              <SingleReview review={review} />
            </li>)}
        </ul>
        <div className="pl-4 sm:hidden sm:invisible">
          <Swiper slidesPerView="auto" spaceBetween={24} className="!pl-6" data-sentry-element="Swiper" data-sentry-source-file="FullWidthReviews.tsx">
            {reviews?.map((review, i) => <div key={`swiper-review-${i}`}>
                <SwiperSlide key={`swiper-review-${i}-slide`} className="!w-[80%]">
                  <SingleReview review={review} />
                </SwiperSlide>
                {i === reviews.length - 1 ? <SwiperSlide className="block !w-10" /> : null}
              </div>)}
          </Swiper>
        </div>
        {amountToDisplay < block.reviews?.length && <div className="flex-col items-center justify-center invisible hidden gap-4 md:flex-row md:flex md:visible">
            <ButtonServer content={"Load More Reviews"}
        // stretch={ButtonStretch.full}
        intent={ButtonIntent.DarkCTA} className="mb-2 md:mb-0" onClick={() => {
          setAmount(a => a + 4);
        }} />
            {/* <ButtonServer
             content={"Leave a Review"}
             // stretch={ButtonStretch.full}
             intent={ButtonIntent.OutlinedCTA}
             className=""
             /> */}
          </div>}
      </div>
    </ContentBlockServer> : null;
};
export default FullWidthReviewsClient;